import type COrderingAppPanel from '@/components/ordering/COrderingAppPanel/COrderingAppPanel.vue'
import { transformEmbeddedRoute } from '@/utils/string'

// State

/**
 * @description Whether or not the Ordering App container panel is open
 */
const isPanelOpen = ref<boolean>(false)

/**
 * @description Whether or not the iFrame route has changed. Used to manage reload of the iFrame
 */
const routeChanged = ref<boolean>(false)

/**
 * @description Whether or not the Ordering App container panel is loading
 */
const isPanelLoading = ref<boolean>(false)

/**
 * @description Whether or not the Ordering App container panel has been mounted.
 * This is used to keep the panel mounted after it has been opened for the first time
 */
const hasPanelMounted = ref<boolean>(false)

/**
 * @description The action/route to navigate to within the Ordering App
 * This is not exposed. Refer to useOrderingAppNavigation() for iframeUrl.
 */
const _embeddedRoute = ref<string>('/home')

/**
 * @description The Ordering App Container component ref.
 * This is not exposed. Refer to useOrderingPanelRef() for the public ref.
 */
const _orderingPanelRef = ref<InstanceType<typeof COrderingAppPanel> | null>()

// Composables

/**
 * @description Composable that returns a function ref for the Ordering App iframe
 * @returns A function ref for the Ordering App Container component
 */
export function useOrderingPanelRef() {
  return (element: any) => {
    if (!_orderingPanelRef.value) {
      _orderingPanelRef.value = element
    }
  }
}

/**
 * @description Composable that returns the state of the Ordering App container panel
 */
export function useOrderingPanelState() {
  /**
   * @description Keeps panel mounted after it has been opened for the first time
   */
  const panelMounted = computed(() => isPanelOpen.value || hasPanelMounted.value)

  return {
    isPanelOpen,
    isPanelLoading,
    hasPanelMounted,
    panelMounted,
    routeChanged
  }
}

/**
 * @description Composable that handles navigation within the Ordering App
 */
export function useOrderingAppNavigation() {
  const {
    public: { orderingAppHost }
  } = useRuntimeConfig()

  /**
   * @description The source URL for the Ordering App iframe
   */
  const iframeUrl = computed<string>(() => {
    return orderingAppHost + '/embed?goto=' + _embeddedRoute.value
  })

  /**
   * @description Handles navigation within the Ordering App, including appending UTM codes
   * @param route {string} - Route to navigate to within the Ordering App
   */
  function orderingAppNavTo(route: string): void {
    const query = useRoute().query
    orderingAppNavToEmbeddedRoute(query ? appendQueryParameters(route, query) : route)
  }

  /**
   * @description Handles navigation within the Ordering App
   * @param route {string} - Route to navigate to within the Ordering App
   * @summary This function is safe to call from routing middleware as it does
   * not rely on useRoute to get the query params.
   */
  function orderingAppNavToEmbeddedRoute(route: string): void {
    _embeddedRoute.value = transformEmbeddedRoute(route)
    // if the panel is not open, open it
    openPanel()
    // Set the routeChanged flag to true to reload the iframe
    routeChanged.value = true
  }

  /**
   * @description Opens the Ordering App container panel.
   */
  function openPanel() {
    if (!isPanelOpen.value) {
      isPanelOpen.value = true
    }
  }

  /**
   * @description Closes the Ordering App container panel
   */
  function closePanel() {
    if (isPanelOpen.value) {
      isPanelOpen.value = false
    }

    // If there is an error, reset the panel mounted state
    // to prevent panel flickering on the Error page.
    // Timeout is aligned with the panel transition duration.
    const nuxtError = useError()
    if (hasPanelMounted.value && nuxtError.value) {
      setTimeout(() => {
        hasPanelMounted.value = false
      }, 500)
    }
  }

  return {
    iframeUrl: readonly(iframeUrl),
    orderingAppNavTo,
    orderingAppNavToEmbeddedRoute,
    openPanel,
    closePanel
  }
}
